import { Injectable } from '@angular/core';
import {
  first, map, mergeWith, Observable, Subscription, tap,
} from 'rxjs';
import { filter } from 'rxjs/operators';
import { WebsocketIoMessageService } from '@app/websocket/websocket-io-message.service';
import { TransferChatWsService } from '@app/websocket/transfer-chat-ws.service';
import { LocalStorageService } from '@app/local-storage-service';
import { SideWidgetService } from '@app/side-widget/side-widget.service';
import { SideWidgetEventType } from '@app/side-widget/model/side-widget-event-type';
import { TransferredChatsStorage } from '@app/transferred-chat-storage.service';
import { ChatTransfer } from '@app/model/chat-transfer';
import { ChatMessageCountersUpdate } from '@app/model/chat-message-counters-update';
import { InviteToChatWsService } from '@app/websocket/invite-to-chat-ws.service';
import { InnerOperatorRequestService } from '@app/gql-service/inner-operator-request/inner-operator-request.service';
import {
  Chat,
  ChatStatusName,
  InnerOperatorRequest,
  InnerOperatorRequestStatus,
  Message,
  MessageType,
  UnreadChatsCounter,
} from './graphql/graphql';
import { MessageCounterGqlService } from './gql-service/message-counter-gql.service';

@Injectable({ providedIn: 'root' })
export class UnreadMessagesCounterService {
  private readonly unreadMessagesByChatIdMap: Map<number, number> = new Map();

  private readonly unreadCommentsByChatIdMap: Map<number, number[]> = new Map();

  private unreadChatsByChatStatusMap: Map<ChatStatusName, number>
    = new Map();

  private subscriptions: Subscription[] = [];

  private requestedInnerOperatorRequests: InnerOperatorRequest[];

  private acceptedInnerOperatorRequests: InnerOperatorRequest[];

  constructor(
    private messageCounterGqlService: MessageCounterGqlService,
    private transferredChatsStorage: TransferredChatsStorage,
    private wsMessageService: WebsocketIoMessageService,
    private transferChatWsService: TransferChatWsService,
    private localStorageService: LocalStorageService,
    private sideWidgetService: SideWidgetService,
    private inviteToChatWsService: InviteToChatWsService,
    private innerOperatorRequestService: InnerOperatorRequestService,
  ) {
  }

  setUnreadMessagesAndComments(
    chatId: number,
    messagesCount: number,
    commentIds: number[],
  ) {
    this.setUnreadMessagesCount(chatId, messagesCount);
    this.setUnreadComments(chatId, commentIds);
  }

  setUnreadMessagesCount(chatId: number, counter: number) {
    this.unreadMessagesByChatIdMap.set(chatId, counter);
  }

  setUnreadComments(chatId: number, commentIds: number[]) {
    const modifiableIdList = [...commentIds];

    this.unreadCommentsByChatIdMap.set(chatId, modifiableIdList);
  }

  getUnreadMessagesCount(chatId: number): number {
    return this.unreadMessagesByChatIdMap.get(chatId) || 0;
  }

  getUnreadComments(chatId: number) {
    return this.unreadCommentsByChatIdMap.get(chatId) || [];
  }

  getUnreadCommentsCount(chatId: number) {
    return this.getUnreadComments(chatId).length;
  }

  getFirstTopLevelCommentId(chatId: number): number {
    const commentIds = this.unreadCommentsByChatIdMap.get(chatId)
      .slice()
      .sort((a, b) => a - b);

    return commentIds.getFirst();
  }

  getTopLevelCommentsWithUnreadThreadCommentsForChatWithId(chatId: number) {
    const commentIds = this.getUnreadComments(chatId);
    const uniqueCommentIds = new Set<number>();

    commentIds.forEach((commentId) => uniqueCommentIds.add(commentId));

    return uniqueCommentIds;
  }

  decreaseCommentsCount(
    commentId: number,
    chatId: number,
    chatStatus: ChatStatusName,
  ) {
    const commentIdsLength = this.getUnreadCommentsCount(chatId);

    if (!commentIdsLength) {
      return;
    }

    const filteredCommentIds = this.getUnreadComments(chatId)
      .filter((messageId) => messageId !== commentId);

    this.setUnreadComments(chatId, filteredCommentIds);

    const removedCount = commentIdsLength - filteredCommentIds.length;
    const messagesCount = this.getUnreadMessagesCount(chatId);

    if (messagesCount > removedCount) {
      this.setUnreadMessagesCount(chatId, messagesCount - removedCount);
    } else {
      this.resetUnreadMessages(chatId, chatStatus);
    }

    this.shareCounterStateWithSideWidgetExtension();
  }

  resetUnreadMessagesAndComments(
    chatId: number,
    chatStatusName: ChatStatusName,
  ) {
    this.setUnreadMessagesAndComments(chatId, 0, []);
    this.removeChatFromUnread(chatStatusName);
  }

  resetUnreadMessages(chatId: number, chatStatusName: ChatStatusName) {
    const unreadCommentsCount = this.getUnreadCommentsCount(chatId);

    this.setUnreadMessagesCount(chatId, unreadCommentsCount);

    if (unreadCommentsCount === 0) {
      this.removeChatFromUnread(chatStatusName);
    }
  }

  removeChatFromCache(chatId: number) {
    this.unreadMessagesByChatIdMap.delete(chatId);
    this.unreadCommentsByChatIdMap.delete(chatId);
  }

  incrementUnreadMessagesIfNeeded(message: Message): void {
    const chatId = Number(message.chat.id);
    const isUserMessageWhenVirtualOperatorDisabled = message.senderUser
      && !this.isVirtualOperatorResponsibleForChat(message.chat);

    const isChatTransferredToCurrentOperator = this.transferredChatsStorage
      .isChatTransferredToOperator(
        chatId,
        this.localStorageService.getCurrentOperatorId(),
      );
    const isMessageFromAnotherOperatorToInvited
      = message.senderOperator && this.isInvitedToChat(chatId)
      && !this.localStorageService.isCurrentOperator(message.senderOperator.id);

    const isMessageFromInvitedOperatorToResponsible
      = message.senderOperator
      && !this.localStorageService.isCurrentOperator(message.senderOperator.id)
      && this.localStorageService
        .isCurrentOperator(message.chat.chatOperator?.operator.id);

    const isTransferInfoInAcceptedInviteChat = this.isAcceptedInviteChat(chatId)
      && message.messageType === MessageType.ChatTransferredInfo;

    if (!message.isEdit && !isTransferInfoInAcceptedInviteChat
      && (isUserMessageWhenVirtualOperatorDisabled
        || isChatTransferredToCurrentOperator
        || isMessageFromAnotherOperatorToInvited
        || isMessageFromInvitedOperatorToResponsible)) {
      const currentUnreadMessages = this.getUnreadMessagesCount(chatId);

      this.setUnreadMessagesCount(chatId, currentUnreadMessages + 1);

      const shouldAddChats = message.chat.status !== ChatStatusName.Processing
        || this.isResponsibleOrInvitedOperatorForChat(message.chat)
        || isMessageFromAnotherOperatorToInvited;

      if (currentUnreadMessages === 0 && shouldAddChats) {
        this.addUnreadChat(message.chat.status);
      }

      this.addNewCommentIfNeeded(message);
    }

    this.shareCounterStateWithSideWidgetExtension();
  }

  initUnreadChatsCount(): Observable<UnreadChatsCounter[]> {
    this.subscribeToEvents();

    return this.messageCounterGqlService.getUnreadChatsCounter()
      .pipe(
        tap((unreadChatsCounter) => {
          const newCountersMap = new Map();

          unreadChatsCounter.forEach((counter) => newCountersMap.set(
            counter.chatStatus,
            counter.unreadChatsCount,
          ));
          this.unreadChatsByChatStatusMap = newCountersMap;
        }),
        tap(this.shareCounterStateWithSideWidgetExtension.bind(this)),
      );
  }

  getUnreadChatsCount(chatStatusName: ChatStatusName): number {
    return this.unreadChatsByChatStatusMap.get(chatStatusName) || 0;
  }

  addUnreadChat(chatStatusName: ChatStatusName) {
    this.changeUnreadChatsAmountBy(chatStatusName, 1);
  }

  removeChatFromUnread(chatStatusName: ChatStatusName) {
    this.changeUnreadChatsAmountBy(chatStatusName, -1);
  }

  hasCounterForChat(chatId: number) {
    return this.unreadMessagesByChatIdMap.has(chatId);
  }

  unsubscribe(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  processAcceptedChatTransfer(chatTransfer: ChatTransfer) {
    const isRequestedByCurrentOperator = this.localStorageService
      .isCurrentOperator(chatTransfer.transferOperatorId);

    if (this.getUnreadMessagesCount(chatTransfer.chatId) !== 0
      && (isRequestedByCurrentOperator || this.transferredChatsStorage
        .isChatTransferred(chatTransfer.chatId))) {
      const chatStatusName = chatTransfer.transferOperatorId
      === this.localStorageService.getCurrentOperatorId()
        ? chatTransfer.chatStatusName
        : ChatStatusName.New;

      this.removeChatFromUnread(chatStatusName);
    }
  }

  subscribeToEvents() {
    this.subscriptions.push(
      this.wsMessageService.onInboxMessage()
        .pipe(
          mergeWith(this.wsMessageService.onInboxMessageAll()),
          mergeWith(this.wsMessageService.onProcessingMessage()),
          mergeWith(this.wsMessageService.onProcessingMessageAll()),
          mergeWith(this.transferChatWsService.onNewMessageInTransferredChat()),
          tap(this.incrementUnreadMessagesIfNeeded.bind(this)),
        ).subscribe(),
      this.wsMessageService.onChatTakenFromNew()
        .pipe(
          tap(() => this.removeChatFromUnread(ChatStatusName.New)),
        ).subscribe(),
      this.wsMessageService.onMarkedAsSpam()
        .pipe(
          filter(
            (message) => this.getUnreadMessagesCount(message.chatId) > 0,
          ),
          tap(() => this.removeChatFromUnread(ChatStatusName.New)),
        ).subscribe(),
      this.wsMessageService.onChatTakenFromNewByCurrentOperator()
        .pipe(
          filter((message) => this.getUnreadMessagesCount(message.chatId) > 0
            || message.unreadMessagesCounter > 0),
          tap((message) => {
            this.removeChatFromUnread(ChatStatusName.New);
            this.addUnreadChat(ChatStatusName.Processing);
            this.setUnreadMessagesCount(
              message.chatId,
              message.unreadMessagesCounter,
            );
          }),
        ).subscribe(),
      this.wsMessageService.onArchiveChat()
        .pipe(
          tap((chatArchivedEvent) => {
            if (chatArchivedEvent.previousChatStatus === ChatStatusName.New) {
              this.removeChatFromUnread(ChatStatusName.New);
            }

            this.setUnreadMessagesAndComments(chatArchivedEvent.chatId, 0, []);
          }),
        ).subscribe(),
      this.wsMessageService.onAutomaticComplete()
        .pipe(
          tap((chatPreview) => {
            if (this.getUnreadMessagesCount(chatPreview.id)) {
              this.removeChatFromUnread(ChatStatusName.Processing);
            }

            this.setUnreadMessagesAndComments(chatPreview.id, 0, []);
          }),
        ).subscribe(),
      this.wsMessageService.onChatCompletedByCurrentOperator()
        .subscribe((message) => {
          if (message.unreadMessagesCounter) {
            this.removeChatFromUnread(message.chatStatus);
          }

          this.setUnreadMessagesAndComments(message.chatId, 0, []);
        }),
      this.wsMessageService.onMessageReadByCurrentOperator()
        .subscribe((message) => {
          const currentMessagesCounter
            = this.getUnreadMessagesCount(message.chatId);
          const newMessagesCounter = message.unreadMessagesCounter;

          this.setUnreadMessagesCount(message.chatId, newMessagesCounter);

          if (currentMessagesCounter !== 0 && newMessagesCounter === 0) {
            this.removeChatFromUnread(message.chatStatus);
          }
        }),
      this.transferChatWsService.onChatTransferFromAdmin()
        .pipe(
          tap((chatTransfer) => {
            if (chatTransfer.operator.id
              === this.localStorageService.getCurrentOperatorId()
              && this.getUnreadMessagesCount(chatTransfer.chatId) !== 0) {
              this.addUnreadChat(ChatStatusName.Processing);
            }
          }),
        ).subscribe(),
      this.transferChatWsService.onChatTransferRequested()
        .pipe(
          filter(
            (chatTransfer) => chatTransfer
              .transferOperatorId !== this.localStorageService
              .getCurrentOperatorId(),
          ),
          tap((chatTransfer) => {
            this.transferredChatsStorage.addChat(chatTransfer);
            this.transferredChatsStorage
              .removeChatFromMovedByAdmin(String(chatTransfer.chatId));

            if (this.isAcceptedInviteChat(chatTransfer.chatId)) {
              const currentUnreadMessages = this.getUnreadMessagesCount(
                chatTransfer.chatId,
              );

              this.setUnreadMessagesCount(
                chatTransfer.chatId,
                currentUnreadMessages + 1,
              );

              if (currentUnreadMessages === 0) {
                this.addUnreadChat(ChatStatusName.Processing);
              }
            } else {
              this.addUnreadChat(ChatStatusName.New);
              this.setUnreadMessagesCount(
                chatTransfer.chatId,
                chatTransfer.unreadMessagesCounter + 1,
              );
            }
          }),
        ).subscribe(),
      this.transferChatWsService.onChatTransferCancelled()
        .pipe(
          tap((chatTransfer) => {
            if (this.isAcceptedInviteChat(chatTransfer.chatId)) {
              const { chatId } = chatTransfer;
              const currentUnreadMessages = this.getUnreadMessagesCount(chatId);

              if (currentUnreadMessages) {
                this.setUnreadMessagesCount(chatId, currentUnreadMessages - 1);
              }

              if (!this.getUnreadMessagesCount(chatId)) {
                this.removeChatFromUnread(ChatStatusName.Processing);
              }
            } else if (
              chatTransfer
                .transferOperatorId !== this.localStorageService
                .getCurrentOperatorId()
            ) {
              this.removeChatFromUnread(ChatStatusName.New);
            }
          }),
        ).subscribe(),
      this.wsMessageService.onChatMessageCountersUpdated()
        .subscribe(this.updateChatCounters.bind(this)),
      this.innerOperatorRequestService.getInnerOperatorRequests(
        InnerOperatorRequestStatus.Requested,
      ).pipe(first())
        .subscribe((requests) => {
          requests.forEach(
            (request) => {
              this.setUnreadMessagesAndComments(
                Number(request.chat.id),
                request.unreadMessagesCounter,
                request.chat.unreadCommentIds,
              );
            },
          );
          this.requestedInnerOperatorRequests = requests;
        }),
      this.innerOperatorRequestService.getInnerOperatorRequests(
        InnerOperatorRequestStatus.Accepted,
      ).pipe(first())
        .subscribe((requests) => {
          requests.forEach(
            (request) => {
              this.setUnreadMessagesAndComments(
                Number(request.chat.id),
                request.unreadMessagesCounter,
                request.chat.unreadCommentIds,
              );
            },
          );
          this.acceptedInnerOperatorRequests = requests;
        }),
      this.inviteToChatWsService.onChatInvitationUpdated().pipe(
        filter((event) => this.localStorageService.isCurrentOperator(
          event.innerOperatorRequest.requestedOperator.id,
        )),
        map((event) => event.innerOperatorRequest),
      ).subscribe((updatedRequest) => {
        if (updatedRequest.status === InnerOperatorRequestStatus.Accepted) {
          this.acceptedInnerOperatorRequests
            = [...this.acceptedInnerOperatorRequests, updatedRequest];
          this.setUnreadMessagesCount(Number(updatedRequest.chat.id), 0);
        } else {
          this.acceptedInnerOperatorRequests = this
            .acceptedInnerOperatorRequests.slice()
            .filter((request) => request.id !== updatedRequest.id);

          if (updatedRequest.status === InnerOperatorRequestStatus.Closed
            && this.transferredChatsStorage.isChatTransferredToOperator(
              Number(updatedRequest.chat.id),
              this.localStorageService.getCurrentOperatorId(),
            )) {
            this.removeChatFromUnread(ChatStatusName.Processing);
          }
        }

        this.requestedInnerOperatorRequests = this
          .requestedInnerOperatorRequests.slice()
          .filter((request) => request.id !== updatedRequest.id);

        if (updatedRequest.status !== InnerOperatorRequestStatus.Closed) {
          this.removeChatFromUnread(ChatStatusName.New);
        }
      }),
      this.inviteToChatWsService.onChatInvitationRequested()
        .subscribe(() => this.addUnreadChat(ChatStatusName.New)),
      this.inviteToChatWsService.onChatInvitationCounterReset()
        .subscribe((event) => {
          const currentMessagesCounter
            = this.getUnreadMessagesCount(event.chatId);
          const currentOperatorId = this.localStorageService
            .getCurrentOperatorId();
          const newCounter = this.transferredChatsStorage
            .isChatTransferredToOperator(event.chatId, currentOperatorId)
            ? 1
            : 0;

          this.setUnreadMessagesCount(event.chatId, newCounter);

          if (currentMessagesCounter !== 0 && newCounter === 0) {
            this.removeChatFromUnread(event.chatStatus);
          }
        }),
      this.wsMessageService.onReadMessageAll().pipe(
        filter((message) => !this.isResponsibleOrInvitedOperatorForChat(
          message.chat,
        )),
      ).subscribe((message) => {
        this.setUnreadMessagesCount(Number(message.chat.id), 0);
      }),
      this.transferChatWsService.onChatTransferAcceptedByCurrentOperator()
        .subscribe(() => {
          this.removeChatFromUnread(ChatStatusName.New);
        }),
    );
  }

  private updateChatCounters(update: ChatMessageCountersUpdate) {
    const chatId = Number(update.chat.id);

    const currentUnreadMessagesCounter
      = update.chat.status === ChatStatusName.New
    || this.isChatOwner(update.chat)
        ? update.chat.unreadMessagesCounter
        : update.chat.unreadMessagesCounter + 1;

    this.setUnreadMessagesCount(
      chatId,
      currentUnreadMessagesCounter,
    );

    update.readThreadMessagesTopLevelCommentIds
      .forEach(
        (threadCommentId) => {
          const unreadCommentIds: number[] = this.getUnreadComments(chatId)
            .filter((commentId) => commentId !== threadCommentId);
          const filteredCommentIds = this.getUnreadComments(chatId)
            .filter((commentId) => commentId === threadCommentId);

          this.setUnreadComments(
            chatId,
            unreadCommentIds.concat(filteredCommentIds.slice(1)),
          );
        },
      );

    if (
      currentUnreadMessagesCounter === 0
      && !(
        this.unreadCommentsByChatIdMap.get(chatId)?.length > 0
        || this.unreadMessagesByChatIdMap.get(chatId) > 0
      )
    ) {
      this.unreadCommentsByChatIdMap.delete(chatId);
      this.unreadMessagesByChatIdMap.delete(chatId);
      this.removeChatFromUnread(update.chat.status);
    }
  }

  private addNewCommentIfNeeded(message: Message): void {
    const isThreadComment = message.messageType === MessageType.Comment
      && !!message.replyToMessage;

    if (isThreadComment) {
      const commentIds = this.getUnreadComments(Number(message.chat.id));

      commentIds.push(Number(message.replyToMessage.id));
      this.setUnreadComments(Number(message.chat.id), commentIds);
    }
  }

  private changeUnreadChatsAmountBy(
    chatStatusName: ChatStatusName,
    amountToAdd: number,
  ) {
    const currentChatsCounter = this.getUnreadChatsCount(chatStatusName);
    const newCounter = Math.max(0, currentChatsCounter + amountToAdd);

    this.unreadChatsByChatStatusMap.set(chatStatusName, newCounter);
    this.shareCounterStateWithSideWidgetExtension();
  }

  private shareCounterStateWithSideWidgetExtension(): void {
    const newUnreadChatsCounter: number
      = this.getUnreadChatsCount(ChatStatusName.New);
    const processingUnreadChatsCounter: number
      = this.getUnreadChatsCount(ChatStatusName.Processing);
    const unreadChatsCounter: number
      = newUnreadChatsCounter + processingUnreadChatsCounter;

    this.sideWidgetService.sendEventToExtension(
      SideWidgetEventType.UPDATE_UNREAD_CHATS_COUNTER,
      {
        unreadChatsCounter,
        newUnreadChatsCounter,
        processingUnreadChatsCounter,
      },
    );
  }

  private isVirtualOperatorResponsibleForChat(chat: Chat): boolean {
    return !!chat.chatOperator?.operator?.virtualOperator?.id;
  }

  private isChatOwner(chat: Chat): boolean {
    return chat.status === ChatStatusName.Processing && (
      !this.transferredChatsStorage.isChatTransferred(Number(chat.id))
      || this.localStorageService
        .isCurrentOperator(
          this.transferredChatsStorage.getChatTransferByChatId(
            Number(chat.id),
          ).transferOperatorId,
        )
    );
  }

  private isInvitedToChat(chatId: number): boolean {
    return this.isAcceptedInviteChat(chatId)
      || this.isRequestedInviteChat(chatId);
  }

  private isAcceptedInviteChat(chatId: number): boolean {
    return this.acceptedInnerOperatorRequests?.some(
      (request) => Number(request.chat.id) === chatId,
    );
  }

  private isRequestedInviteChat(chatId: number): boolean {
    return this.requestedInnerOperatorRequests?.some(
      (request) => Number(request.chat.id) === chatId,
    );
  }

  private isResponsibleOrInvitedOperatorForChat(chat: Chat) {
    const isCurrentOperator = this.localStorageService.isCurrentOperator(
      chat.chatOperator?.operator?.id,
    );

    return isCurrentOperator || this.isAcceptedInviteChat(Number(chat.id));
  }
}
