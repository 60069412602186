import { ErrorHandler, Injectable } from '@angular/core';
import StackTracey from 'stacktracey';
import { LoggerService } from '@app/logger.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private loggerService: LoggerService) { }

  handleError(error: any): void {
    const errorMessage = error.message
      ? error.message
      : error.toString();
    const stack = error.stack
      ? new StackTracey(error.stack).withSources().asTable()
      : '';
    const status = error.status
      ? error.status
      : -1;

    if (status === 404) {
      this.loggerService.warn(`${errorMessage} ${stack}`);

      return;
    }

    this.loggerService.error(`${errorMessage} ${stack}`);
  }
}
