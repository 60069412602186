import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  private baseUrl = environment.apiBaseUrl;

  private loggerInfoUrl = `${this.baseUrl}logger/info`;

  private loggerErrorUrl = `${this.baseUrl}logger/error`;

  private loggerWarnUrl = `${this.baseUrl}logger/warn`;

  private isProduction = environment.env === 'production';

  constructor(private httpClient: HttpClient) {
  }

  info(message: string): void {
    if (this.isProduction) {
      this.httpClient.post<void>(this.loggerInfoUrl, message).subscribe();
    } else {
      // eslint-disable-next-line no-console
      console.log('INFO', message);
    }
  }

  error(message: string): void {
    if (this.isProduction) {
      this.httpClient.post<void>(this.loggerErrorUrl, message).subscribe();
    } else {
      // eslint-disable-next-line no-console
      console.error('ERROR', message);
    }
  }

  warn(message: string): void {
    if (this.isProduction) {
      this.httpClient.post<void>(this.loggerWarnUrl, message).subscribe();
    } else {
      // eslint-disable-next-line no-console
      console.warn('WARN', message);
    }
  }
}
