import { Component, Inject } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { map } from 'rxjs';
import { ChannelType, Type } from '@app/model/channel-type';
import { Tag } from '@app/common/model/tag';
import { toLowerCaseWithCapitalFirstLetter } from '@app/util/text.util';
import { MessengerProviderName, MessengerType } from '@app/graphql/graphql';
import { AddChannelModalService } from '@app/settings/channels-page/add-channel-modal/service/add-channel-modal-service';
import { FeatureFlagsService } from '@app/feature-flags.service';
import { ModalWindow } from '../modal-window';

@Component({
  selector: 'app-create-viber-channel',
  templateUrl: './create-viber-channel.component.html',
  styleUrls: ['./create-viber-channel.component.scss'],
})
export class CreateViberChannelComponent implements ModalWindow {
  private modalRef: NzModalRef;

  readonly channelTypes: ChannelType[] = [
    {
      type: Type.BOT,
      title: 'Viber Bot',
      description: this.translationService.t('bot_channel.description'),
      isPaid: false,
    },
    {
      type: Type.DIRECT,
      title: this.translationService.t('e_chat_channel', { messenger: toLowerCaseWithCapitalFirstLetter(MessengerType.Viber) }),
      description: this.translationService.t('e_chat_channel.description', { messenger: toLowerCaseWithCapitalFirstLetter(MessengerType.Viber) }),
      isPaid: true,
      providerName: MessengerProviderName.EChat,
      display: this.featureFlagsService.isDisabledFeaturesEnabled()
        .pipe(map((isEnabled) => !isEnabled)),
    },
  ];

  currentChannelType: ChannelType;

  showBackButton = false;

  constructor(
      private addChannelModalService: AddChannelModalService,
      private modalService: NzModalService,
      private featureFlagsService: FeatureFlagsService,
      @Inject(I18NEXT_SERVICE)
      private translationService: ITranslationService,
  ) { }

  showModal(showBackButton?: boolean): void {
    this.modalService.closeAll();

    const modalRef = this.modalService.create(
      {
        nzWidth: '572px',
        nzMaskClosable: false,
        nzContent: CreateViberChannelComponent,
        nzFooter: null,
      },
    );

    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.showBackButton = showBackButton;
  }

  selectChannelType(channelType: ChannelType): void {
    this.modalRef.updateConfig(
      {
        nzTitle: null,
        nzFooter: null,
      },
    );
    this.currentChannelType = channelType;
  }

  deselectChannelType(): void {
    this.modalRef.updateConfig(
      {
        nzTitle: null,
        nzFooter: null,
      },
    );
    this.currentChannelType = null;
  }

  back(): void {
    this.modalService.closeAll();
    this.addChannelModalService.showModal();
  }

  protected readonly Tag = Tag;

  protected readonly MessengerType = MessengerType;
}
