import { Injectable } from '@angular/core';
import {
  getMessaging, getToken, isSupported,
} from 'firebase/messaging';
import { Router } from '@angular/router';
import { LoggerService } from '@app/logger.service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  constructor(
    private loggerService: LoggerService,
    private router: Router,
  ) {
  }

  async requestPermission(): Promise<void> {
    const messaging = (async () => {
      try {
        const isSupportedBrowser = await isSupported();

        if (isSupportedBrowser) {
          return getMessaging();
        }

        this.loggerService.warn('Firebase not supported in this browser');

        return null;
      } catch (error) {
        this.loggerService.warn(`Some error while getting info about support in this browser: ${error}`);

        return null;
      }
    })();

    try {
      const messagingResolve = await messaging;
      const currentToken = await getToken(
        messagingResolve,
        { vapidKey: environment.firebase.vapidKey },
      );

      if (currentToken) {
        sessionStorage.setItem('firebaseToken', currentToken);
      } else {
        this.loggerService.warn('No registration token available. Request permission to generate one.');
      }
    } catch (error) {
      this.loggerService.warn(`An error occurred while retrieving token: ${error}`);
    }
  }

  onNotificationClick() {
    navigator.serviceWorker.addEventListener(
      'message',
      (event) => {
        if (!event.data.action) {
          return;
        }

        if (event.data.action === 'redirect-from-notificationclick') {
          this.router.navigate([event.data.url]);
        }
      },
    );
  }
}
