import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject, Observable, throwError,
} from 'rxjs';
import {
  catchError, filter, retry, switchMap, take,
} from 'rxjs/operators';
import { AuthService } from '@app/auth/auth.service';
import { BaseResponse } from '@app/model/base-response';
import { LoginResponse } from '@app/auth/login-response';
import { EXCLUDED_URLS } from '@app/excluded-url.constants';
import { LoggerInterceptor } from '@app/logger-interceptor.service';
import { TokenStorageService } from './token-storage.service';

const refreshPathname = '/api/refresh';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  private refreshTokenSubject = new BehaviorSubject<string>(null);

  constructor(
    private tokenStorageService: TokenStorageService,
    private authService: AuthService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (EXCLUDED_URLS.find((url) => request.url.startsWith(url))) {
      return next.handle(request);
    }

    const token = this.tokenStorageService.getAccessToken();

    if (token != null) {
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (checkHttpErrorResponseForbiddenStatus(error)
            && new URL(error.url).pathname === refreshPathname) {
          this.authService.onLogout();

          return throwError(error);
        }

        if (
          checkHttpErrorResponseForbiddenStatus(error)
            && this.tokenStorageService.hasRefreshToken()
        ) {
          return this.refreshToken(request, next);
        }

        return throwError(error);
      }),
      retry(3),
    );
  }

  private refreshToken(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((data: BaseResponse<LoginResponse>) => {
          const newAccessToken = data.value.accessToken;

          this.refreshTokenSubject.next(newAccessToken);
          this.isRefreshing = false;

          return next.handle(this.addToken(request, newAccessToken));
        }),
      );
    }

    return this.refreshTokenSubject.pipe(
      filter((token) => token != null),
      take(1),
      switchMap((token) => next.handle(this.addToken(request, token))),
    );
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

function checkHttpErrorResponseForbiddenStatus(error) {
  return error instanceof HttpErrorResponse
      && error.status === HttpStatusCode.Forbidden;
}

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoggerInterceptor,
    multi: true,
  },
];
