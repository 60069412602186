import { Observable } from 'rxjs';
import { MessengerProviderName } from '../graphql/graphql';

export interface ChannelType {
  type: Type;
  title: string;
  description: string;
  isPaid: boolean;
  providerName?: MessengerProviderName;
  display?: Observable<boolean>;
}

export enum Type {
  BOT = 'BOT',
  DIRECT = 'DIRECT',
}
