export const WEBSOCKET_EVENT_TYPE = {
  companyMessengerUserInformationChanged: 'COMPANY_MESSENGER_USER_INFORMATION_CHANGED',
  companyMessengerUserInformationDeleted: 'COMPANY_MESSENGER_USER_INFORMATION_DELETED',
  selectedNew: 'SELECTED_NEW',
  selectedArchived: 'SELECTED_ARCHIVED',
  cancelledNew: 'CANCELLED_NEW',
  cancelledArchived: 'CANCELLED_ARCHIVED',
  automaticComplete: 'AUTOMATIC_COMPLETE',
  automaticChatTransfer: 'AUTOMATIC_CHAT_TRANSFER',
  markedAsSpam: 'MARKED_AS_SPAM',
  takenFromNew: 'TAKEN_FROM_NEW',
  takenFromArchived: 'TAKEN_FROM_ARCHIVED',
  takenFromVirtualOperator: 'TAKEN_FROM_VIRTUAL_OPERATOR',
  archived: 'ARCHIVED',
  categoriesChangedNew: 'CATEGORIES_CHANGED_NEW',
  categoriesChangedProcessing: 'CATEGORIES_CHANGED_PROCESSING',
  categoriesChangedAll: 'CATEGORIES_CHANGED_ALL',
  contactSubmitted: 'CONTACT_SUBMITTED',
  messageRead: 'MESSAGE_READ',
  markSeen: 'MARK_SEEN',
  messageReacted: 'MESSAGE_REACTED',
  messageUnreacted: 'MESSAGE_UNREACTED',
  chatMessageCountersUpdated: 'CHAT_MESSAGE_COUNTERS_UPDATED',

  messageProcessing: 'MESSAGE_PROCESSING',
  messageProcessingAll: 'MESSAGE_PROCESSING_ALL',
  messageNew: 'MESSAGE_NEW',
  messageNewAll: 'MESSAGE_NEW_ALL',
  messageChanged: 'MESSAGE_CHANGED',
  messageSystem: 'MESSAGE_SYSTEM',
  messageDelivered: 'MESSAGE_DELIVERED',
  messageFailed: 'MESSAGE_FAILED',

  // events from-to same operator (working in 2+ tabs)
  takenFromNewByCurrentOperator: 'TAKEN_FROM_NEW_BY_CURRENT_OPERATOR',
  completedByCurrentOperator: 'COMPLETED_BY_CURRENT_OPERATOR',
  messageReadByCurrentOperator: 'MESSAGE_READ_BY_CURRENT_OPERATOR',
  chatTransferAcceptedByCurrentOperator: 'CHAT_TRANSFER_ACCEPTED_BY_CURRENT_OPERATOR',

  // chat invitation
  chatInvitationCreated: 'CHAT_INVITATION_CREATED',
  chatInvitationUpdated: 'CHAT_INVITATION_UPDATED',
  chatInvitationRequested: 'CHAT_INVITATION_REQUESTED',
  chatInvitationCounterReset: 'CHAT_INVITATION_COUNTER_RESET',

  // chat transferred events
  chatTransferRequested: 'CHAT_TRANSFER_REQUESTED',
  chatTransferAccepted: 'CHAT_TRANSFER_ACCEPTED',
  chatTransferByAdmin: 'CHAT_TRANSFER_BY_ADMIN',
  chatTransferFromAdmin: 'CHAT_TRANSFER_FROM_ADMIN',
  automaticChatTransferCancelation: 'AUTOMATIC_CHAT_TRANSFER_CANCELATION',
  chatTransferCanceled: 'CHAT_TRANSFER_CANCELED',
  chatTransferRejected: 'CHAT_TRANSFER_REJECTED',
  transferredChatMessageNew: 'TRANSFERRED_CHAT_MESSAGE_NEW',
  transferredChatMessageUpd: 'TRANSFERRED_CHAT_MESSAGE_UPD',
  transferredChatMessageRead: 'TRANSFERRED_CHAT_MESSAGE_READ',

  // creatio
  creatioLookupFetched: 'CREATIO_LOOKUP_FETCHED',

  // notification
  notificationReceived: 'NOTIFICATION_RECEIVED',

  // chat action
  messageTyping: 'MESSAGE_TYPING',

  // shopify-billing
  shopifySubscriptionActivationHandled: 'SHOPIFY_SUBSCRIPTION_ACTIVATION_HANDLED',

  // operator
  operatorUpdated: 'OPERATOR_UPDATED',

  // operator availability
  operatorAvailabilityUpdated: 'OPERATOR_AVAILABILITY_UPDATED',
  operatorAvailabilityUpdatedAll: 'OPERATOR_AVAILABILITY_UPDATED_ALL',

  // chat rescue
  chatRescueUpdate: 'CHAT_RESCUE_UPDATE',

  // messenger notifications
  messengerNotificationChatConnected: 'MESSENGER_NOTIFICATION_CHAT_CONNECTED',

  // whatsapp template messages
  whatsappTemplateMessageUpdated: 'WHATSAPP_TEMPLATE_MESSAGE_UPDATED',
  whatsappTemplateMessagePulled: 'WHATSAPP_TEMPLATE_MESSAGES_PULLED',

  // meta media
  metaMediaChanged: 'META_MEDIA_CHANGED',
  metaMediaDeleted: 'META_MEDIA_DELETED',
};
