import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BehaviorSubject, Observable, Subject,
} from 'rxjs';
import {
  map, tap,
} from 'rxjs/operators';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { ROUTE_URL } from '@app/router-url.constants';
import { AppInfoMessageStyle } from '@app/info-message-card/info-message-card.component';
import { LoadingService } from '@app/loading/loading.service';
import { SettingsFieldsValidationService } from '@app/settings-fields-validation.service';
import { ChannelFragment, MessengerProviderName, MessengerType } from '@app/graphql/graphql';
import { SubscriberComponent } from '@app/common/extends/subscriber-component';
import {
  WidgetPreviewService,
} from './widget-preview/widget-preview.service';
import { ChannelDetailsService } from './service/channel-details.service';
import { InfoMessageService } from './service/info-message.service';

@Component({
  selector: 'app-channel-details-page',
  templateUrl: './channel-details-page.component.html',
  styleUrls: ['./channel-details-page.component.scss'],
  providers: [
    LoadingService,
  ],
})
export class ChannelDetailsPageComponent
  extends SubscriberComponent
  implements OnInit, OnDestroy {
  private readonly tabMenuItems: BehaviorSubject<TabMenuItemView[]>
    = new BehaviorSubject<TabMenuItemView[]>([]);

  private readonly installedChannels = [
    MessengerType.Whatsapp,
    MessengerType.Widget,
  ];

  private readonly installedMessengerProviderNames: MessengerProviderName[] = [
    MessengerProviderName.EChat,
  ];

  readonly channelsUrl: string = ROUTE_URL.settingsChannels;

  readonly isWidgetPreviewShown$: Observable<boolean>
    = this.widgetPreviewService.isWidgetPreviewShown$;

  readonly tabMenuItems$: Observable<TabMenuItemView[]>
    = this.tabMenuItems.asObservable();

  channelFragment$!: Observable<ChannelFragment>;

  onSaveButtonClickEventNew!: Subject<ChannelFragment>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private channelDetailsService: ChannelDetailsService,
    private loadingService: LoadingService,
    private detailsMessageService: InfoMessageService,
    public fieldsValidationService: SettingsFieldsValidationService,
    private widgetPreviewService: WidgetPreviewService,
    @Inject(I18NEXT_SERVICE)
    private translationService: ITranslationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.channelDetailsService.initNew(
      this.loadingService,
      this.activatedRoute.params,
    );
    this.channelFragment$ = this.channelDetailsService.channelFragment$
      .pipe(tap(this.updateInfoMessages.bind(this)));
    this.onSaveButtonClickEventNew
      = this.channelDetailsService.onSaveButtonClickedEvent;

    this.tabMenuItems.next(this.getTabMenuItem());
  }

  ngOnDestroy(): void {
    this.channelDetailsService.reset();
    super.ngOnDestroy();
  }

  getChannelViewName(messengerName: MessengerType): string {
    if (this.isWidgetChannel(messengerName)) {
      return 'Website widget';
    }

    const messenger = messengerName.valueOf().toLowerCase();

    return messenger[0].toUpperCase() + messenger.slice(1);
  }

  private updateInfoMessages(channel: ChannelFragment): void {
    this.detailsMessageService.clear();
    if (this.isWidgetChannel(channel?.messenger.type)
      && !channel.widgetProperty.isInstalled) {
      this.detailsMessageService.add({
        text: this.translationService.t('settings.channel_details.widget_has_not_been_installed'),
        tab: InfoMessageTab.INSTALLATION,
        style: AppInfoMessageStyle.ERROR,
      });
    }
  }

  private getMessageCounterValue(
    filterTab: InfoMessageTab,
  ): Observable<number> {
    return this.detailsMessageService.infoMessages$
      .pipe(map((messages) => messages
        .filter((message) => message.tab === filterTab).length));
  }

  private isWidgetChannel(messengerName: MessengerType): boolean {
    return messengerName === MessengerType.Widget;
  }

  private isGreetingsTabHidden(messengerType: MessengerType): boolean {
    return messengerType === MessengerType.Email;
  }

  private getTabMenuItem(): TabMenuItemView[] {
    return [
      {
        title: this.translationService.t('settings.channel_details.general'),
        tab: InfoMessageTab.GENERAL,
        uri: ROUTE_URL.settingsChannelsGeneral,
        messageCounter: this.getMessageCounterValue(InfoMessageTab.GENERAL),
      },
      {
        title: this.translationService.t('settings.channel_details.assign'),
        tab: InfoMessageTab.ASSIGN,
        uri: ROUTE_URL.settingsChannelsAssign,
        messageCounter: this.getMessageCounterValue(InfoMessageTab.ASSIGN),
        disable: true,
        // TODO: remove this when assign tab will be implemented
        isHidden: () => true,
      },
      {
        title: this.translationService.t('settings.channel_details.greetings'),
        tab: InfoMessageTab.GREETINGS,
        uri: ROUTE_URL.settingsChannelsGreetings,
        messageCounter: this.getMessageCounterValue(InfoMessageTab.GREETINGS),
        isHidden: (channel) => this.isGreetingsTabHidden(
          channel.messenger.type,
        ),
      },
      {
        title: this.translationService.t('settings.channel_details.widget_settings'),
        tab: InfoMessageTab.WIDGET_SETTINGS,
        uri: ROUTE_URL.settingsChannelsWidgetSettings,
        messageCounter:
          this.getMessageCounterValue(InfoMessageTab.WIDGET_SETTINGS),
        isHidden: (channel) => !this.isWidgetChannel(channel.messenger.type),
      },
      {
        title: this.translationService.t('settings.channel_details.chat_assignment'),
        tab: InfoMessageTab.CHAT_ASSIGNMENT,
        uri: ROUTE_URL.settingsChannelsChatAssignment,
        messageCounter:
          this.getMessageCounterValue(InfoMessageTab.CHAT_ASSIGNMENT),
      },
      {
        title: this.translationService.t('settings.channel_details.installation'),
        tab: InfoMessageTab.INSTALLATION,
        uri: ROUTE_URL.settingsChannelsInstallation,
        messageCounter: this
          .getMessageCounterValue(InfoMessageTab.INSTALLATION),
        isHidden:
          (channel) => !this.installedChannels.includes(channel.messenger.type)
          && !this.installedMessengerProviderNames.includes(
            channel.messengerProvider?.name as MessengerProviderName,
          ),
      },
    ];
  }
}

export enum InfoMessageTab {
  GENERAL='General',
  ASSIGN='Assign',
  GREETINGS='Greetings',
  WIDGET_SETTINGS='WIDGET_SETTINGS',
  CHAT_ASSIGNMENT='Chat assignment',
  CHANNELS='Channels',
  INSTALLATION='Installation',
  CHAT_DETAILS='ChatDetails',
  TEXT_AREA='TEXT_AREA',
  CRM_INTEGRATION='CRMIntegration',
}

interface TabMenuItemView {
  title: string;
  uri: string;
  messageCounter: Observable<number>;
  disable?: boolean;
  isHidden?: (channel: ChannelFragment) => boolean;
  tab: InfoMessageTab;
}
