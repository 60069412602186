import {
  Component, Inject,
} from '@angular/core';
import { FacebookLoginProvider, SocialAuthService } from 'angularx-social-login';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  combineLatestWith, Observable, switchMap, throwError,
} from 'rxjs';
import {
  catchError, map, tap,
} from 'rxjs/operators';
import { NzMessageService } from 'ng-zorro-antd/message';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { FacebookUserService } from '@app/facebook-user.service';
import { FacebookPage } from '@app/model/facebook-page';
import { LoadingService } from '@app/loading/loading.service';
import { MessengerName } from '@app/model/old-messenger-name';
import { MetaPermissionService } from '@app/meta-permission.service';
import { ModalWindow } from '@app/sign-up-channels-page/channels/create-channel-buttons/modal-window';
import { ChannelsService } from '@app/settings/channels-page/channels.service';
import { BotFeature, ChannelFragment, MessengerType } from '@app/graphql/graphql';
import { ErrorService } from '@app/error.service';
import { LoggerService } from '@app/logger.service';
import { BOT_FEATURES } from '@app/model/bot-feature';
import { EMPTY_LIST } from '@app/util/array.util';

@Component({
  selector: 'app-create-facebook-channel',
  templateUrl: './create-facebook-channel.component.html',
  styleUrls: ['./create-facebook-channel.component.scss'],
  providers: [
    LoadingService,
  ],
})
export class CreateFacebookChannelComponent implements ModalWindow {
  pages$: Observable<FacebookPage[]>;

  currentStep = 0;

  selectedPage: FacebookPage = null;

  assignToAllOperators = true;

  private facebookUserId: number;

  private socialUser = null;

  private readonly commentFeatureSwitchLoadingService: LoadingService
    = new LoadingService();

  readonly isCommentFeatureSwitchLoading$: Observable<boolean>
    = this.commentFeatureSwitchLoadingService.loading$;

  savedChannel: ChannelFragment;

  constructor(
    private authService: SocialAuthService,
    private facebookUserService: FacebookUserService,
    private notificationService: NzNotificationService,
    private metaPermissionService: MetaPermissionService,
    private nzModalService: NzModalService,
    private nzMessageService: NzMessageService,
    private channelPageService: ChannelsService,
    private loadingService: LoadingService,
    private message: NzMessageService,
    @Inject(I18NEXT_SERVICE)
    private translationService: ITranslationService,
    private loggerService: LoggerService,
    private errorService: ErrorService,
  ) {
  }

  showModal(): void {
    this.nzModalService.closeAll();
    this.nzModalService.create({
      nzTitle: this.translationService.t('connecting-facebook'),
      nzWidth: '40%',
      nzMaskClosable: false,
      nzOnCancel: () => this.handleCancel(),
      nzContent: CreateFacebookChannelComponent,
    });
  }

  openFacebookWindow() {
    this.loadingService.loadingOn();
    this.metaPermissionService
      .getPermissionsByMessenger(MessengerName.Facebook.toString())
      .subscribe((permissions) => {
        const fbLoginOptions = {
          scope: this.metaPermissionService
            .joinPermissions(permissions),
        };

        this.authService.signIn(
          FacebookLoginProvider.PROVIDER_ID,
          fbLoginOptions,
        ).then((user) => {
          if (user != null) {
            this.socialUser = user;
            this.currentStep += 1;
            this.addPages(user);
          }
        })
          .catch((error) => {
            this.notificationService.create('error', this.translationService.t('connecting-facebook'), error);
            this.loadingService.loadingOff();
          });
      });
  }

  setSelectedPage(page: FacebookPage): void {
    this.selectedPage = page;
  }

  addChannel(): void {
    const selectedPages = [this.selectedPage];
    const channelToCreate = {
      facebookUserId: this.facebookUserId,
      messengerType: MessengerType.Facebook,
      pageIds: selectedPages.map((page) => String(page.id)),
    };

    const loadingBots$ = this.channelPageService.addMetaChannel(
      channelToCreate,
      this.assignToAllOperators,
    );

    this.loadingService
      .showLoaderUntilCompleted<ChannelFragment[]>(loadingBots$)
      .subscribe({
        next: ([savedChannel]): void => {
          this.savedChannel = savedChannel;
          this.currentStep = 2;
          this.signOutFacebook();
          this.message.success(this.translationService.t('facebook-channel-created'));
        },
        error: this.handleError.bind(this),
      });
  }

  updateDisplayCommentsFeature(isDisplayCommentsEnabled: boolean): void {
    const featureSlugs: string[] = isDisplayCommentsEnabled
      ? [BOT_FEATURES.displayComments]
      : EMPTY_LIST;

    this.commentFeatureSwitchLoadingService.showLoaderUntilCompleted(
      this.channelPageService.updateChannelFeatures(
        {
          channelId: this.savedChannel.id,
          featureSlugs,
        },
      ).pipe(
        catchError(this.handleCommentFeatureSwitchError.bind(this)),
        tap((features: BotFeature[]): void => {
          this.savedChannel = {
            ...this.savedChannel,
            features,
          };

          this.channelPageService.changeChannelProperties(this.savedChannel);
        }),
      ),
    ).subscribe();
  }

  private handleCommentFeatureSwitchError(): void {
    this.nzMessageService.error(this.translationService.t('something-went-wrong'));
  }

  finish(): void {
    this.nzModalService.closeAll();
  }

  private addPages(user): void {
    const facebookUserInfo = {
      email: user.email,
      name: user.name,
      firstName: user.firstName,
      lastName: user.lastName,
      avatarUrl: user.photoUrl,
      shortLivedAccessToken: user.authToken,
      userId: user.id,
    };

    this.socialUser = facebookUserInfo;

    const facebookUserId$ = this.facebookUserService.create(facebookUserInfo)
      .pipe(
        catchError((err: Error) => {
          this.currentStep = 0;
          this.notificationService.create(
            'error',
            this.translationService.t('unexpected-error'),
            this.translationService.t('something-went-wrong'),
          );

          return throwError(() => err);
        }),
      );

    const loadedPages$ = facebookUserId$.pipe(
      tap((fbId: number) => {
        this.facebookUserId = fbId;
      }),
      switchMap((id) => this.facebookUserService.getPages(id)),
    );

    const loadedChannels$ = facebookUserId$.pipe(
      switchMap((id) => this.facebookUserService.getChannels(id, 'ACTIVE', MessengerName.Facebook)),
    );

    const formattedPages$ = loadedPages$.pipe(
      combineLatestWith(loadedChannels$),
      map(([pages, channels]) => pages.map((page) => {
        const correctChannel = channels
          .find((channel) => channel.pageId === page.id.toString());

        page.isConnected = !!correctChannel;

        return page;
      })),
    );

    this.pages$ = this.loadingService
      .showLoaderUntilCompleted<FacebookPage[]>(formattedPages$);
  }

  private handleCancel(): void {
    this.signOutFacebook();
    this.nzModalService.closeAll();
    this.currentStep = 0;
  }

  private handleError(error): void {
    this.loggerService.warn(`Could not create FB channel: ${error}`);
    const parsedValue = this.errorService.isJson(error?.message)
      ? JSON.parse(error?.message)
      : error?.message;
    const content = parsedValue?.value || this.translationService.t('something-went-wrong');
    const title = this.translationService.t('unexpected-error');

    this.nzModalService.closeAll();
    this.notificationService.create('error', title, content);
  }

  private signOutFacebook(): void {
    if (this.socialUser) {
      this.authService.signOut();
      this.socialUser = null;
    }
  }
}
