import { Component, OnInit } from '@angular/core';
import {
  Subject, tap,
} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
// eslint-disable-next-line import/no-extraneous-dependencies
import { TimeagoIntl } from 'ngx-timeago';
// eslint-disable-next-line import/no-extraneous-dependencies
import { strings as enStrings } from 'ngx-timeago/language-strings/en';
// eslint-disable-next-line import/no-extraneous-dependencies
import { strings as ukStrings } from 'ngx-timeago/language-strings/uk';
import { strings as ruStrings } from 'ngx-timeago/language-strings/ru';
import { MessagingService } from '@app/messaging.service';
import { CusboWidgetApiService } from '@app/cusbo-widget/cusbo-widget-api.service';
import { LocalStorageService } from '@app/local-storage-service';
import { ResponsiveService } from '@app/responsive/service/responsive.service';
import { Breakpoint } from '@app/responsive/model/breakpoint';
import { PwaInstallationService } from '@app/responsive/service/pwa-installation.service';
import { SideWidgetService } from '@app/side-widget/side-widget.service';
import { NotificationService } from '@app/service/notification.service';
import { OperatorUpdatedWsDto } from '@app/model/dto/operator/operator-updated-ws-dto';
import { LOCAL_STORAGE_KEY } from '@src/constants/local_storage.constants';
import { OperatorWsService } from '@app/websocket/operator-ws.service';

const LANGUAGE_TO_TIMEAGO_STRINGS = {
  en: enStrings,
  uk: ukStrings,
  ru: ruStrings,
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private readonly isComponentDestroyed = new Subject<boolean>();

  constructor(
    private messagingService: MessagingService,
    private localStorageService: LocalStorageService,
    private operatorWsService: OperatorWsService,
    private cusboWidgetApiService: CusboWidgetApiService,
    private responsiveService: ResponsiveService,
    private pwaInstallationService: PwaInstallationService,
    private sideWidgetService: SideWidgetService,
    private notificationService: NotificationService,
    private intl: TimeagoIntl,
  ) {
    this.setTimeagoLanguage();
  }

  ngOnInit(): void {
    this.pwaInstallationService.beforeInstallPromptEvent$.pipe(
      takeUntil(this.isComponentDestroyed),
    ).subscribe();

    this.messagingService.requestPermission();
    this.messagingService.onNotificationClick();

    this.responsiveService.isMatched$(Breakpoint.DESKTOP)
      .pipe(
        takeUntil(this.isComponentDestroyed),
        map((isDesktop) => isDesktop),
      ).subscribe((shouldShowWidget) => {
        if (shouldShowWidget) {
          this.cusboWidgetApiService.loadWidgetIfNeeded();

          if (this.localStorageService.getCurrentOperatorId()) {
            this.cusboWidgetApiService.authenticateWidgetUser({
              companyId: this.localStorageService.getCompanyId(),
              operatorId: this.localStorageService.getCurrentOperatorId(),
            });
          }

          this.cusboWidgetApiService.displayCusboWidget();
        } else {
          this.cusboWidgetApiService.hideCusboWidget();
        }
      });

    this.sideWidgetService.shareRouteChanges()
      .pipe(takeUntil(this.isComponentDestroyed))
      .subscribe();

    this.notificationService.subscribeOnInvitedChatsNotification();

    this.operatorWsService.onOperatorUpdated()
      .pipe(
        tap(
          (dto: OperatorUpdatedWsDto) => {
            this.localStorageService.set(
              LOCAL_STORAGE_KEY.roles,
              dto.roleNames.join(),
            );

            window.location.reload();
          },
        ),
      )
      .subscribe();
  }

  private setTimeagoLanguage(): void {
    this.intl.strings
      = LANGUAGE_TO_TIMEAGO_STRINGS[this.localStorageService.getLanguage()];
    this.intl.changes.next();
  }
}
