import { Injectable } from '@angular/core';
import {
  Message, MessageMode, MessageStatus, MessageType,
} from '@app/graphql/graphql';
import { LocalStorageService } from '@app/local-storage-service';
import { ANSWERED_INFLUENCING_MESSAGE_TYPES } from '@app/conversation-page/chat-preview-update-gql.service';
import { exists } from '@app/util/object.util';

export const REPLACE_ALL_MATCHES_FLAG = 'g';

@Injectable({
  providedIn: 'root',
})
export class MessageUtilService {
  constructor(
    private localStorageService: LocalStorageService,
  ) {
  }

  private readonly messageTypesCountedInCounters = [
    MessageType.BotBlockedInfo,
    MessageType.ContactShare,
    MessageType.UserInformationShare,
  ];

  postProcessMessageText(message: Message): Message {
    if (!message.text) {
      return {
        ...message,
        text: 'File',
      };
    }

    if (message.messageType === MessageType.UserInformationShare) {
      return {
        ...message,
        text: this.postProcessUserInformationShareMessage(message.text),
      };
    }

    return message;
  }

  postProcessUserInformationShareMessage(text: string): string {
    const openingDivRegex = new RegExp(
      '<div.*?>',
      REPLACE_ALL_MATCHES_FLAG,
    );
    const closingDivOrTwoBlankLinesRegex = new RegExp(
      '</div>|\\n\\n',
      REPLACE_ALL_MATCHES_FLAG,
    );

    return text.replace(openingDivRegex, '')
      .replace(closingDivOrTwoBlankLinesRegex, '\n');
  }

  isThreadCommentFromThirdPartyUser(message: Message): boolean {
    return message.senderUser
      && message.senderUser.externalUserId !== message.chat.externalChatId;
  }

  isCountedInCountersMessageWithType(messageType: MessageType): boolean {
    return this.messageTypesCountedInCounters.includes(messageType);
  }

  isThreadComment(message: Message) {
    return message.messageType === MessageType.Comment
      && message.replyToMessage;
  }

  isInfoMessage(message: Message) {
    return message?.messageType === MessageType.Info;
  }

  isTopLevelComment(message: Message) {
    return message.messageType === MessageType.Comment
      && !message.replyToMessage;
  }

  isPendingMessage(message: Message) {
    return message.messageStatus === MessageStatus.Pending;
  }

  isErrorMessage(message: Message) {
    return message.messageStatus === MessageStatus.Error;
  }

  isStandardMessage(message: Message): boolean {
    return !this.isPrivateMessage(message);
  }

  isFromChat(chatId: string, message: Message): boolean {
    return Number(message.chat.id) === Number(chatId);
  }

  isPrivateMessage(message: Message): boolean {
    return message.mode === MessageMode.Private;
  }

  isMessageFromCurrentOperator(message: Message): boolean {
    return this.localStorageService
      .isCurrentOperator(message.senderOperator?.id);
  }

  isMessageFromOperatorOrBot(message: Message): boolean {
    return (!message.senderUser
        || !message.senderUser.companyMessengerUser?.firstName)
      && message.messageType !== 'INFO'
      && message.messageType !== 'SELECTED_COMMAND'
      && message.messageType !== 'CHAT_TRANSFER_COMMENT'
      && (this.isStandardMessage(message)
        || this.isMessageFromCurrentOperator(message));
  }

  isAnswerToUser(wasChatAnswered: boolean, newMessage: Message): boolean {
    if (
      !ANSWERED_INFLUENCING_MESSAGE_TYPES.includes(newMessage.messageType)
      || this.isPrivateMessage(newMessage)
    ) {
      return wasChatAnswered;
    }

    return exists(newMessage.senderOperator);
  }
}
