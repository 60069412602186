/* eslint-disable dot-notation */
/* eslint-disable quotes */

export const environment = {
  env: window["env"]["env"] || "default",
  production: false,
  ip: window["env"]["ip"] || "default",
  cusboServerlessRootUrl: window["env"]["cusboServerlessRootUrl"] || "default",
  apiBaseUrl: window["env"]["apiBaseUrl"] || "default",
  websocketUrl: window["env"]["websocketUrl"] || "default",
  metaAppId: window["env"]["metaAppId"] || "default",
  firebase: {
    apiKey: window["env"]["firebaseApiKey"] || "default",
    authDomain: window["env"]["firebaseAuthDomain"] || "default",
    databaseURL: window["env"]["firebaseDatabaseURL"] || "default",
    projectId: window["env"]["firebaseProjectId"] || "default",
    storageBucket: window["env"]["firebaseStorageBucket"] || "default",
    messagingSenderId: window["env"]["firebaseMessagingSenderId"] || "default",
    appId: window["env"]["firebaseAppId"] || "default",
    measurementId: window["env"]["firebaseMeasurementId"] || "default",
    vapidKey: window["env"]["firebaseVapidKey"] || "default",
  },
};
