import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  EMPTY, Observable, of, switchMap, tap, throwError,
} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FIREBASE_URL } from '@app/excluded-url.constants';
import { LOCAL_STORAGE_KEY } from '@src/constants/local_storage.constants';
import { LoggerService } from '@app/logger.service';
import { LocalStorageService } from '@app/local-storage-service';
import { GetFirebaseAccessTokenGQL } from '@app/graphql/graphql';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  constructor(
    private http: HttpClient,
    private loggerService: LoggerService,
    private localStorageService: LocalStorageService,
    private getFirebaseAccessTokenGql: GetFirebaseAccessTokenGQL,
  ) {}

  sendPushNotification(
    notificationBody: string,
    redirectUrl: string,
    notificationUUID: number,
  ): Observable<void> {
    const token = sessionStorage.getItem('firebaseToken');

    if (token === null) {
      this.loggerService.warn(`Firebase push notification: token is null for operator with id - ${
        this.localStorageService.get(LOCAL_STORAGE_KEY.operator)}`);

      return EMPTY;
    }

    return this.getFirebaseAccessToken()
      .pipe(
        switchMap(
          (accessToken) => {
            const httpOptions = {
              headers: new HttpHeaders(
                {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${accessToken}`,
                },
              ),
            };

            const body = {
              message: {
                notification: {
                  title: 'Cusbo',
                  body: notificationBody,
                },
                data: {
                  uuid: `${notificationUUID}`,
                  icon: `${environment.ip}favicon.png`,
                  click_action: redirectUrl,
                },
                token,
              },
            };

            return this.http.post<void>(FIREBASE_URL, body, httpOptions)
              .pipe(
                catchError(
                  (error) => {
                    this.loggerService.warn(`Firebase push notification: send error - ${JSON.stringify(error)}`);

                    this.loggerService.info(`Update Firebase access token - ${JSON.stringify(error)}`);

                    this.localStorageService.remove(
                      LOCAL_STORAGE_KEY.firebaseAccessToken,
                    );

                    this.sendPushNotification(
                      notificationBody,
                      redirectUrl,
                      notificationUUID,
                    ).subscribe();

                    return throwError(error);
                  },
                ),
              );
          },
        ),
      );
  }

  private getFirebaseAccessToken(): Observable<string> {
    const accessToken = this.localStorageService.get(
      LOCAL_STORAGE_KEY.firebaseAccessToken,
    );

    if (accessToken) {
      return of(accessToken);
    }

    return this.getFirebaseAccessTokenGql.fetch(
      {},
      {
        fetchPolicy: 'network-only',
      },
    ).pipe(
      map((data) => data.data.firebaseAccessToken as string),
      tap(
        (token) => this.localStorageService.set(
          LOCAL_STORAGE_KEY.firebaseAccessToken,
          token,
        ),
      ),
    );
  }
}
