<app-loading></app-loading>
<ng-container [ngSwitch]="currentChannelType?.type">
  <ng-container *ngSwitchCase="null">
    <ng-container *nzModalTitle>{{ 'connecting-telegram' | i18next }}</ng-container>
    <div class="title">{{ 'channel.type.choose' | i18next }}</div>
    <ng-container *ngFor="let channelType of channelTypes; let i = index">
      <div
        *ngIf="!channelType.display || (channelType.display | async)"
        class="channel-type-card"
        (click)="selectChannelType(channelType)"
        data-testid="channel-type-card"
      >
        <app-messenger-icon
          class="channel-type-card__icon"
          [messengerName]="MessengerType.Telegram"
          [messengerProviderName]="channelType.providerName">
        </app-messenger-icon>
        <div class="channel-type-card__info-block">
          <div class="channel-type-card__title-block">
            <div class="channel-type-card__title-block__title">{{ channelType.title }}</div>
            <app-tag *ngIf="channelType.isPaid" [tag]="Tag.PAID"></app-tag>
          </div>
          <div class="channel-type-card__description">{{ channelType.description }}</div>
        </div>
      </div>
      <nz-divider  class="channel-type-card__divider" *ngIf="i < channelTypes.length - 1 && (channelType.display | async)"></nz-divider>
    </ng-container>
    <div *ngIf="showBackButton">
      <div *nzModalFooter>
        <button
            class="footer-button"
            (click)="back()"
            nz-button
            nzType="default">
          {{ 'back' | i18next }}
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'BOT'">
    <ng-container *nzModalTitle>{{ 'connecting-telegram' | i18next }}</ng-container>
    <app-telegram-bot-setup (back)="deselectChannelType()"></app-telegram-bot-setup>
  </ng-container>
  <ng-container *ngSwitchCase="'DIRECT'">
    <ng-container *nzModalTitle>{{ 'connecting-telegram' | i18next }}</ng-container>
    <app-telegram-direct-setup (back)="deselectChannelType()"></app-telegram-direct-setup>
  </ng-container>
</ng-container>
