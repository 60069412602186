import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloLink, fromPromise, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { HttpClientModule } from '@angular/common/http';
import { onError } from '@apollo/client/link/error';
import { SafeReadonly } from '@apollo/client/cache/core/types/common';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { environment } from '@src/environments/environment';
import { AuthService } from '@app/auth/auth.service';
import { GQL_ERROR_TYPE } from '@src/constants/gql-error-type.constants';
import {
  ChannelFragment, ChatPreviewInfo,
  OperatorNotification, TemplateMessage,
} from '@app/graphql/graphql';
import { LoggerService } from '@app/logger.service';
import { ROUTE_URL } from '@app/router-url.constants';
import { parseIfValidJson } from '@app/util/json.util';
import { TokenStorageService } from '@app/auth/token-storage.service';

const graphqlUrl = `${environment.ip}${environment.apiBaseUrl}graphql`;
const uri = graphqlUrl;

export function createApollo(
  httpLink: HttpLink,
  tokenStorageService: TokenStorageService,
  authService: AuthService,
  loggerService: LoggerService,
  router: Router,
  modal: NzModalService,
) {
  const basic = setContext(() => ({
    headers: {
      Accept: 'charset=utf-8',
    },
  }));

  const auth = setContext(() => {
    const token = tokenStorageService.getAccessToken();

    if (token === null) {
      return {};
    }

    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const errorLink = onError(
    ({
      graphQLErrors, networkError, operation, forward,
    }) => {
      if (graphQLErrors) {
        loggerService.info(`[Graphql Error]: ${JSON.stringify(graphQLErrors)}`);
        /* eslint-disable-next-line */
        for (const error of graphQLErrors) {
          if (error.message === GQL_ERROR_TYPE.forbidden) {
            if (tokenStorageService.hasRefreshToken()) {
              // TODO: replace REST refresh() method with GraphQL
              return fromPromise(authService.refreshToken().toPromise())
                .filter((value) => Boolean(value))
                .flatMap(() => forward(operation));
            }
          }

          const message = parseIfValidJson(error.message);

          if (message?.status === 402) {
            modal.closeAll();
            router.navigate([ROUTE_URL.subscriptionPlans]);
          }
        }
      }

      if (networkError) {
        loggerService.info(`[Network Error]: ${JSON.stringify(networkError)}`);
      }

      return forward(operation);
    },
  );

  const link = ApolloLink
    .from([errorLink, basic, auth, httpLink.create({ uri })]);

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          categoryGroups: {
            merge(existing, incoming) {
              return incoming;
            },
          },
          allChatPreviews: {
            read(allChatPreviews: SafeReadonly<ChatPreviewInfo>) {
              return allChatPreviews;
            },
            merge(existing, incoming) {
              return incoming;
            },
          },
          channels: {
            read(channels: SafeReadonly<ChannelFragment>) {
              return channels;
            },
            merge(existing, incoming) {
              return incoming;
            },
          },
          allTemplateMessages: {
            read(allTemplateMessages: SafeReadonly<TemplateMessage>) {
              return allTemplateMessages;
            },
            merge(existing, incoming) {
              return incoming;
            },
          },
          innerOperatorRequests: {
            keyArgs: ['status'],
            merge(existing, incoming) {
              return incoming;
            },
          },
          operatorNotifications: {
            read(operatorNotifications: SafeReadonly<OperatorNotification>) {
              return operatorNotifications;
            },
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      CategoryGroup: {
        fields: {
          categories: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  });

  return {
    link,
    cache,
  };
}

@NgModule({
  exports: [ApolloModule, HttpClientModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, TokenStorageService, AuthService,
        LoggerService, Router, NzModalService],
    },
  ],
})
export class GraphQLModule {}
