<app-loading></app-loading>

<nz-steps [nzCurrent]="currentStepIndex">
  <nz-step></nz-step>
  <nz-step></nz-step>
  <nz-step></nz-step>
  <nz-step></nz-step>
  <nz-step></nz-step>
</nz-steps>

<ng-container [ngSwitch]="currentStepIndex">
  <ng-container *ngSwitchCase="0">
    <div class="description head-block">
      <div class="description__title">{{'select.provider' | i18next}}</div>
      <div class="description__text">{{'select.whatsapp.business.provider' | i18next}}</div>
    </div>

    <nz-radio-group class="messenger-providers" [ngModel]="selectedProviderId$ | async">
      <div
        class="messenger-provider clickable-option"
        *ngFor="let provider of messengerProviders$ | async"
        (click)="setSelectedProvider(provider)"
      >
        <div class="messenger-provider__details">
          <img
            class="messenger-provider__details__logo"
            *ngIf="toMessengerProviderIconName(provider) as iconName"
            src="assets/images/icons/messenger-provider/{{ iconName }}"
            [alt]="iconName"
          >

          <div
            class="messenger-provider__details__info"
            *ngIf="toMessengerProviderName(provider) | capitalWord as providerName"
          >
            <div class="messenger-provider__details__info__name">
              {{ providerName }}
            </div>
            <a
              class="messenger-provider__details__info__pricing-link"
              [href]="provider.pricingUrl"
              target="_blank"
            >
              {{'provider.detailed.prices' | i18next: { providerName: providerName} }}
            </a>
          </div>
        </div>

        <div class="messenger-provider__utils">
          <label
            nz-radio
            [nzValue]="provider.id"
            (click)="setSelectedProvider(provider)"
          ></label>
        </div>
      </div>

      <div class="messenger-provider">
        <div class="messenger-provider__details">
          <img
            class="messenger-provider__details__logo"
            src="../../../../../../assets/images/icons/messenger-provider/skeleton.svg"
            alt="skeleton"
          >

          <div class="messenger-provider__details__info">
            <div class='messenger-provider__details__info__skeleton-text'>
              {{'other.providers.on.the.way' | i18next}}
            </div>
          </div>
        </div>
      </div>
    </nz-radio-group>
  </ng-container>

  <ng-container *ngSwitchCase="1">
    <div class="description head-block">
      <div class="description__title">
        {{ 'create-channel.whats-app.channel-details.title' | i18next }}
      </div>
    </div>

    <form
      nz-form
      nzLayout="vertical"
      [formGroup]="generalChannelDataStepFormGroup"
    >
      <nz-form-item *ngFor="let inputField of generalChannelDataStepFields">
        <nz-form-label
          nzTooltipIcon="info-circle"
          [nzTooltipTitle]="inputField.tooltipSlug | i18next"
        >
          {{ inputField.labelSlug | i18next }}
        </nz-form-label>
        <nz-form-control [nzErrorTip]="getErrorTip(inputField.name, generalChannelDataStepFormGroup)">
          <input
            nz-input
            [mask]="inputField.mask"
            [formControlName]="inputField.name"
            [placeholder]="inputField.placeholderSlug | i18next"
          />
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-container>

  <ng-container *ngSwitchCase="2">
    <div class="description head-block" *ngIf="toMessengerProviderName(selectedProvider$ | async) | capitalWord as selectedProviderName">
      <div class="description__title">
        {{'connect.with.provider' | i18next: { providerName: selectedProviderName} }}
      </div>
      <div class="description__text">
        {{'fill.in.fields.to.connect.with.provider' | i18next: { providerName: selectedProviderName} }}
      </div>
    </div>

    <form
      nz-form
      nzLayout="vertical"
      [formGroup]="providerCredentialDataStepFromGroup"
    >
      <nz-form-item *ngFor="let inputField of providerCredentialDataStepFields">
        <nz-form-label
          nzTooltipIcon="info-circle"
          [nzTooltipTitle]="inputField.tooltipSlug | i18next"
        >
          {{ inputField.labelSlug | i18next }}
        </nz-form-label>
        <nz-form-control [nzErrorTip]="getErrorTip(inputField.name, providerCredentialDataStepFromGroup)">
          <input
            nz-input
            [mask]="inputField.mask"
            [formControlName]="inputField.name"
            [placeholder]="inputField.placeholderSlug | i18next"
          />
        </nz-form-control>
      </nz-form-item>

      <label
        nz-checkbox
        class="assign-all-box"
        [formControlName]="ControlName.assignAllMembersToChannel"
      >
        {{'assign.all.members.to.this.channel' | i18next}}
      </label>
    </form>
  </ng-container>

  <ng-container *ngSwitchCase="3">
    <ng-container *ngIf="selectedProvider$ | async as selectedProvider" [ngSwitch]="selectedProvider.name">
      <ng-container *ngSwitchCase="'TWILIO'">
        <div class="description head-block">
          <div class="description__title">Twilio Webhook URL</div>
          <div class="description__text">
            {{'whatsapp.twilio.copy.and.paste.url' | i18next}}
            <div class="webhook-url-instruction">
              <img class="webhook-url-instruction__icon" src="../../../../../../assets/images/icons/external-link.svg" alt="external-link">
              <a
                target="_blank"
                class="webhook-url-instruction__link"
                href="https://doc.clickup.com/9012381837/d/h/8cjw24d-20392/776146a4d24b07c">
                {{ 'sign_up_channels.add_whatsapp_channel.how_to_setup_twilio_webhook' | i18next }}
              </a>
            </div>
          </div>
        </div>

        <div class="webhook-url-block" *ngIf="creationResponse">
          <input
            nz-input
            [disabled]="true"
            [value]="creationResponse.webhookUrl"
          >
          <app-copy-button [textToCopy]="creationResponse.webhookUrl"></app-copy-button>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngSwitchCase="4">
    <div class="description">
      <div class="description__title">
        {{ 'channel.whats-app.add-templates.title' | i18next }}
      </div>
      <div class="description__text">
        {{ 'channels.whats-app-templates.description' | i18next }}
      </div>
      <img
        class="description__illustration"
        src="../../../../../../assets/images/settings/illustration/whats-app-templates.png"
        alt="whats-app templates illustration"
      >
    </div>
  </ng-container>
</ng-container>

<div *nzModalFooter [ngSwitch]="currentStepIndex">
  <button
    nz-button
    *ngSwitchCase="0"
    nzType="primary"
    [disabled]="isProviderNotSelected$ | async"
    (click)="handleSelectProviderButtonClick()"
  >
    {{'select.provider' | i18next}}
  </button>

  <ng-container *ngSwitchCase="1">
    <button
      nz-button
      nzType="default"
      (click)="previousStep()"
    >
      {{ 'back' | i18next }}
    </button>

    <button
      nz-button
      nzType="primary"
      [disabled]="generalChannelDataStepFormGroup?.invalid"
      (click)="handleConnectWithGeneralDataButtonClick()"
    >
      {{ 'connect' | i18next }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="2">
    <button
      nz-button
      nzType="default"
      (click)="previousStep()"
    >
      {{ 'back' | i18next }}
    </button>

    <button
      nz-button
      nzType="primary"
      [disabled]="providerCredentialDataStepFromGroup?.invalid"
      (click)="handleConnectButtonClick()"
    >
      {{'connect' | i18next}}
    </button>
  </ng-container>

  <button
    *ngSwitchCase="3"
    nz-button
    nzType="primary"
    (click)="nextStep()"
  >
    {{ 'continue' | i18next }}
  </button>

  <ng-container *ngSwitchCase="4">
    <button
      nz-button
      nzType="default"
      (click)="handleDoneButtonClick()"
    >
      {{ 'done' | i18next }}
    </button>
    <a
      nz-button
      nzType="primary"
      (click)="handleAddTemplatesButtonClick()"
    >
      {{ 'add-templates' | i18next }}
    </a>
  </ng-container>
</div>
